import React from "react"
import { Link } from "gatsby"

import "./index.scss"

const Next = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    data-rnw-int-class="nearest__264-1726__"
    className="r-hd655f r-1472mwg r-lrsllp align-middle"
    {...props}
  >
    <path d="M5 12h14m-7-7 7 7-7 7" />
  </svg>
)

const Prev = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    data-rnw-int-class="nearest__264-1726__"
    className="r-hd655f r-1472mwg r-lrsllp align-middle"
    {...props}
  >
    <path d="M19 12H5m7 7-7-7 7-7" />
  </svg>
)

const NextPrev = ({ next = null, prev = null }) => {
  return (
    <nav className="maxapps-next-prev">
      {prev ? (
        <Link className="maxapps-link" to={prev.href}>
          <div className={`maxapps-prev  ${!next && "m-0"}`}>
            <Prev />
            <div className="maxapps-prev-content">
              <span className="maxapps-prev-content-category">
                {prev.category ? `${prev.category} -` : null} Previous
              </span>
              <span className="maxapps-prev-content-title">{prev.title}</span>
            </div>
          </div>
        </Link>
      ) : null}
      {next ? (
        <Link className="maxapps-link" to={next.href}>
          <div className={`maxapps-next  ${!prev && "m-0"}`}>
            <div className="maxapps-next-content">
              <span className="maxapps-next-content-category">
                Next {next.category ? `- ${next.category}` : null}
              </span>
              <span className="maxapps-next-content-title">{next.title}</span>
            </div>
            <Next />
          </div>
        </Link>
      ) : null}
    </nav>
  )
}
export default NextPrev
