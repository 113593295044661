import axios from "axios"
import React, { useState } from "react"
import { FaTelegramPlane } from "react-icons/fa"

import RateEmotion from "./rate-emotion"
import dbServices from "../../../../../../services/firebase/db"

import "./index.scss"
import { getFromStorage } from "../../../../../../services/utils"
const RateForm = ({ openAction, sendAction }) => {
  const [comment, setComment] = useState("")
  const [emoji, setEmoji] = useState("2")
  const Rating = {
    ratingValue: emoji,
    ratingComment: comment,
    ratingDoc: isBrowser() && window.location.pathname,
  }
  const setRateEmoji = select => {
    setEmoji(select)
  }
  const sendFeedback = async () => {
    const userData = await getUserData()
    console.log(userData)
    await dbServices.addFeedback({
      id: Rating?.ratingDoc?.replace(new RegExp("/", "g"), ""),
      pageSlug: Rating?.ratingDoc,
      feedbacks: [
        {
          rating: Rating?.ratingValue,
          comment: Rating?.ratingComment,
          userData,
        },
      ],
    })
  }
  return (
    <div id="RateForm" className="RateForm" data-testid="RateForm">
      <div className="rate-form">
        <h2 className="rate-question">Was this doc helpful to you?</h2>
        <fieldset className="rate-exp">
          <legend>Rate your experience</legend>
          <RateEmotion getEmoji={setRateEmoji} />
        </fieldset>
        <div className="rate-message">
          <label htmlFor="feedback-comment" className="feedback-label">
            Your comments
            <span>(optional)</span>
          </label>
          <div className="rate-comment">
            <textarea
              name="feedback-comment"
              className="feedback-comment"
              id="feedback-comment"
              cols="30"
              rows="10"
              value={comment}
              onChange={e => setComment(e.target.value)}
            ></textarea>
          </div>
          <div className="dialog-btns">
            <button
              className="cancel-btn"
              data-testid="cancelBtn"
              onClick={() => {
                openAction(false)
              }}
            >
              <span>Cancel</span>
            </button>
            <button
              className="send-btn"
              data-testid="sendBtn"
              onClick={async () => {
                sendAction(true)
                await sendFeedback()
              }}
            >
              <span>
                Send feedback <FaTelegramPlane />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const isBrowser = () => typeof window !== "undefined"
const getUserData = async () => {
  const res = await axios.get("https://geolocation-db.com/json/")
  const profileString = getFromStorage("profile")

  const profileLS = JSON.parse(profileString || "{}")
  return {
    userID: profileLS.id || "guest",
    IPv4: res.data.IPv4,
    country_name: res.data.country_name,
  }
}
export default RateForm
