import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import "./index.scss"

const SubHeaderNav = ({ _data, _logo }) => {
  const [clicked, setclicked] = useState(true)
  const [clicked2, setclicked2] = useState(true)
  let contentNavBar = []
  let frontmatter = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(sub-header-nav)/" }) {
          exports {
            data {
              column
              srcIMG
              navLink
            }
          }
          frontmatter {
            logoWeb
            logoMobile
            docTitle
          }
        }
      }
    `)
    contentNavBar = result.mdx.exports.data
    frontmatter = result.mdx.frontmatter
  } catch (error) {
    contentNavBar = _data
    frontmatter = _logo
  }

  return (
    <>
      <div
        id="SubHeaderNav"
        className="SubHeaderNav"
        data-testid="SubHeaderNav"
      >
        <nav
          className="navbar navbar-expand-lg navbar-light "
          data-testid="navbarWeb"
        >
          <Link className="navbar-brand d-none d-lg-block" to="/">
            <img
              alt="MAXapps"
              title="MAXapps"
              src={`${frontmatter?.logoWeb}`}
              className="p-0 m-0 imgMaxapps"
            />
          </Link>
          <Link className="navbar-brand d-block d-lg-none " to="/">
            <img
              alt="MAXapps"
              title="MAXapps"
              src={frontmatter?.logoMobile}
              className="p-0 m-0 imgMaxappsMobile "
            />
          </Link>
          <Link className="docs  d-none d-lg-block me-md-3 " to="/docs/">
            <img
              alt=""
              src={frontmatter?.docTitle}
              className=" imgMaxappsMobile "
            />
          </Link>
          <Link className="docsMobile d-block d-lg-none" to="/docs/">
            <img
              alt=""
              src={frontmatter?.docTitle}
              className=" pt-1 imgMaxappsMobile "
            />
          </Link>

          <button
            className="navbar-toggler "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              setclicked(!clicked)
            }}
          >
            <span
              className={clicked ? "navbar-toggler-icon" : "bi bi-x-lg"}
            ></span>
          </button>
          <div className="collapse navbar-collapse d-none d-lg-block">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {contentNavBar?.map((item, index) => {
                return (
                  <li className="nav-item dropdown" key={index}>
                    <Link
                      className="nav-link dropdown-toggle links"
                      to={item.navLink}
                    >
                      <div className="d-flex flex-row imageContainer  ">
                        <div className=" me-md-2">
                          <img alt="" src={item.srcIMG} className="img-fluid" />
                        </div>
                        <div>{item.column}</div>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>

            <img
              src="/search.svg"
              alt="search"
              className="imgBtn "
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            />
            <div className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle widgets-link"
                to="/widgets/"
              >
                <div className="d-flex flex-row imageContainer  ">
                  <div className=" me-md-2">
                    <img alt="" src="/widgets.svg" className="img-fluid" />
                  </div>
                  <div>Widgets</div>
                </div>
              </Link>
            </div>
            <div></div>
          </div>
        </nav>
        <div id="sectionMobile " className=" d-block d-lg-none">
          <ul
            id="navbarSupportedContent"
            className="ulMobile panel-collapse collapse in"
          >
            <div className="row py-3 justify-content-start "></div>
            {contentNavBar?.map((item, index) => {
              return (
                <div className="border-top  py-4" key={index}>
                  <Link className="links" to={item.navLink}>
                    <div className="d-flex flex-row imageContainerMobile ">
                      <div>
                        <img alt="" src={item.srcIMG} className="img-fluid" />
                      </div>
                      <div className="px-2 ">{item.column}</div>
                    </div>
                  </Link>
                </div>
              )
            })}
            <div className="border-top  py-4">
              <Link className="widgets-link" to="/widgets/">
                <div className="d-flex flex-row imageContainerMobile ">
                  <div>
                    <img alt="" src="/widgets.svg" className="img-fluid" />
                  </div>
                  <div className="px-2 ">Widgets</div>
                </div>
              </Link>
            </div>
          </ul>
        </div>
        <div className=" d-block d-lg-none ">
          <div className=" row">
            <div className="   col-10 pt-1 ">
              <img
                src="/search.svg"
                alt="search"
                className="imgBtn text-end ms-3"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              />
            </div>
            <div className="col-2 text-end p-0 SideBarNav">
              <button
                className="navbar-toggler  "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarNav"
                aria-controls="sidebarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => {
                  setclicked2(!clicked2)
                }}
              >
                <i
                  className={
                    clicked2 ? "bi bi-arrows-expand" : "bi bi-arrows-collapse"
                  }
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubHeaderNav
