import React from "react"

import "./index.scss"
import ListWithSubItems from "./sub-items"
import BackToDocs from "./heading/back-to-docs"
import SideBarTitle from "./side-bar-title"

const SideBar = ({ sideBarEntries = [], withBackToDocs = false }) => {
  return (
    <div id="SideBar" className="wrapper row SideBar">
      {sideBarEntries.length > 0 && (
        <>
          <nav
            id="sidebarNav"
            className="sidebarNav d-block- d-lg-none  panel-collapse collapse in pt-5"
          >
            {withBackToDocs && <BackToDocs />}
            <ul className=" list-unstyled components">
              {sideBarEntries.map((item, index) => {
                return (
                  <li key={index}>
                    {index === 0 ? (
                      <SideBarTitle {...item} />
                    ) : (
                      <ListWithSubItems {...item} />
                    )}
                  </li>
                )
              })}
            </ul>
          </nav>
          <nav id="sidebar" className="sidebarNav d-none d-lg-block">
            {withBackToDocs && <BackToDocs />}
            <ul className=" list-unstyled components">
              {sideBarEntries.map((item, index) => {
                return (
                  <li key={index}>
                    {index === 0 ? (
                      <SideBarTitle {...item} />
                    ) : (
                      <ListWithSubItems {...item} />
                    )}
                  </li>
                )
              })}
            </ul>
          </nav>
        </>
      )}
    </div>
  )
}

export default SideBar
