import React from "react"
import "./index.scss"
import ImageDocs from "../layout/docs-layout/image"

const Figure = ({
  type,
  src,
  alt,
  caption = null,
  double = null,
  children,
}) => {
  return (
    <figure className="maxapps-figure">
      {double ? (
        <div className="maxapps-figure__img">{children}</div>
      ) : (
        <ImageDocs type={type} src={src} alt={alt} />
      )}

      <figcaption>{caption}</figcaption>
    </figure>
  )
}

export default Figure
