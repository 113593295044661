import React from "react"
import "./index.scss"

const RateResponse = ({ openAction }) => {
  return (
    <div id="RateResponse" className="RateResponse" data-testid="RateResponse">
      <h2 className="rate-response">Thank you for your feedback!</h2>
      <div className="rate-msg">
        Your rating and comments can help make MAXapps even better.
      </div>
      <button
        className="rate-dismiss"
        data-testid="dismissBtn"
        onClick={() => {
          openAction(false)
        }}
      >
        <span>Dismiss</span>
      </button>
    </div>
  )
}

export default RateResponse
