import algoliasearch from "algoliasearch/lite"
import { createRef, default as React, useState } from "react"
import { InstantSearch } from "react-instantsearch-dom"
import { ThemeProvider } from "styled-components"
import StyledSearchBox from "../search/styled-search-box"
import StyledSearchResult from "../search/styled-search-result"
import StyledSearchRoot from "../search/styled-search-root"
import useClickOutside from "../search/use-click-outside"
import "./index.scss"
const theme = {
  foreground: "#050505",
  background: "white",
  faded: "#888",
}
const indices = [{ name: `docsPages`, title: "docsPages" }]
export default function Search() {
  const rootRef = createRef()
  const [query, setQuery] = useState("")
  const [hasFocus, setFocus] = useState(true)
  const searchClient = algoliasearch(
    "2I1UA3A6C8",
    "df70157b629f8ab7a34d78dbba3b5ad6"
  )

  useClickOutside(rootRef, () => {
    setQuery("")
    setFocus(false)
  })

  return (
    <div className="ModalSearch">
      <ThemeProvider theme={theme}>
        <StyledSearchRoot ref={rootRef}>
          <InstantSearch
            searchClient={searchClient}
            indexName={indices[0].name}
            onSearchStateChange={({ query }) => setQuery(query)}
          >
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modalLarge ">
                <div className="modal-content">
                  <div className="modal-body content">
                    <div className="searchIcon">
                      <StyledSearchBox
                        onFocus={() => setFocus(true)}
                        hasFocus={hasFocus}
                      />
                    </div>

                    <StyledSearchResult
                      show={query && query.length > 0 && hasFocus}
                      indices={indices}
                    />
                  </div>
                </div>
              </div>
            </div>
          </InstantSearch>
        </StyledSearchRoot>
      </ThemeProvider>
    </div>
  )
}
