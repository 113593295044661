import React from "react"
import { useState } from "react"
import CloseButton from "./close-button"
import RateButton from "./rate-button"
import RateDialog from "./rate-dialog"

const RateModal = () => {
  const [open, setOpen] = useState(false)
  return (
    <div id="rateModal" className="rateModal" data-testid="rateModal">
      {!open && <RateButton openAction={setOpen} />}
      {open && <RateDialog openAction={setOpen} />}
      {open && <CloseButton openAction={setOpen} />}
    </div>
  )
}

export default RateModal
