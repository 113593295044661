import { graphql } from "gatsby"
import * as React from "react"

import { getSidebarEntries, setActivePage } from "./util"
import LayoutDocs from "../components/layout/docs-layout"

const DocPage = ({ data }) => {
  const defaultSideBarEntries = getSidebarEntries(
    data?.allMdx?.nodes?.filter(node => node.fields.source === "docs")
  )

  const slugParts = data.mdx.slug.split("/")
  const parent = slugParts[0]
  let currentEntries =
    slugParts.length === 1
      ? defaultSideBarEntries
      : defaultSideBarEntries.filter(el => {
          return el.text === parent
        })

  setActivePage(currentEntries, data.mdx.slug)
  const slugPartsUpper =
    slugParts[0].charAt(0)?.toUpperCase() + slugParts[0].slice(1)
  const slugPartsUpper2 =
    data.mdx.slug.split("/", 2).pop().charAt(0)?.toUpperCase() +
    data.mdx.slug.split("/", 2).pop().slice(1)
  return (
    <LayoutDocs
      slugParts={
        data.mdx.slug.split("/", 2).pop() === ""
          ? slugPartsUpper
          : slugPartsUpper2
      }
      pageTitle={data.mdx.frontmatter.pageTitle}
      headings={data.mdx.headings}
      sideBarEntries={currentEntries}
      content={data.mdx.body}
      withToC={
        data.mdx.frontmatter.withToC === null
          ? false
          : data.mdx.frontmatter.withToC
      }
      withBackToDocs={currentEntries !== defaultSideBarEntries}
      withRateModal={
        data.mdx.frontmatter.withRateModal === null
          ? true
          : data.mdx.frontmatter.withRateModal
      }
      isClientOnly={true}
      // isClientOnly={data.mdx.frontmatter.isClientOnly}
    />
  )
}

export const query = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      slug
      frontmatter {
        title
        pageTitle
        withToC
        withRateModal
        isClientOnly
      }
      headings {
        depth
        value
      }
      body
    }
    allMdx {
      nodes {
        slug
        frontmatter {
          title
          order
        }
        fields {
          source
        }
      }
    }
  }
`

export default DocPage
