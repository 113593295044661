import { Link } from "gatsby"
import React from "react"
import Slugger from "github-slugger"

import "./index.scss"
const ToC = ({ headings = [] }) => {
  const slugger = new Slugger()

  return headings.length > 0 ? (
    <ul id="ToC" className="toc-container">
      <h6 className="toc-title">TABLE OF CONTENTS</h6>
      <div className="toc-innerScroll">
        {headings.map((heading, index) => {
          return (
            <li key={index} className={`toc-element padding-depth-${heading.depth}`} >
              <Link
                className={`toc-link`}
                to={"#" + slugger.slug(heading.value)}
              >
                {heading.value}
              </Link>
            </li>
          )
        })}
      </div>
    </ul>
  ) : (
    <></>
  )
}

export default ToC
