import React from "react"
import "./index.scss"

const RateButton = ({ openAction }) => {
  return (
    <div id="RateButton" className="RateButton" data-testid="RateButton">
      <button
        className="rate-btn"
        onClick={() => {
          openAction(true)
        }}
      >
        <span className="rate-question-btn">Was this doc helpful to you?</span>
        <span className="chat-icon">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="24px"
            width="24px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"></path>
          </svg>
        </span>
      </button>
    </div>
  )
}
export default RateButton
