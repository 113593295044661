import { Link } from "gatsby"
import React from "react"
import { BsArrowLeft } from "react-icons/bs"

import "./index.scss"

const BackToDocs = () => {
  return (
    <>
      <Link to="/docs/">
        <BsArrowLeft size={15} /> Docs
      </Link>
    </>
  )
}

export default BackToDocs
