import React from "react"
import "./index.scss"
const CloseButton = ({ openAction }) => {
  return (
    <div id="CloseButton" className="CloseButton" data-testid="CloseButton">
      <button
        className="close-btn"
        onClick={() => {
          openAction(false)
        }}
      >
        <span className="close-icon">
          <i className="bi bi-x"></i>
        </span>
      </button>
    </div>
  )
}

export default CloseButton
