import React from "react"
import HeaderNav from "./header-nav"
import SubHeaderNav from "./sub-header-nav"
import "./index.scss"

const HeaderMain = () => {
  return (
    <div className="zindex1 top-0 position-sticky ">
      <HeaderNav />
      <SubHeaderNav />
    </div>
  )
}

export default HeaderMain
