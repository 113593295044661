import { Link } from "gatsby"
import React from "react"
import Slugger from "github-slugger"

import "./index.scss"
import { getSubItemsCount } from "../../../../../templates/util"

const slugger = new Slugger()

function ListWithSubItems({ subItems, text, depth }) {
  let textFilter= (text.match(/-/g)||[]).length >= 1  && text!== "how-to"? text.replace("-"," ") : text;
  
  
  const slug = slugger.slug(text)
  let subItemCount = getSubItemsCount(subItems)
  const expandedList = isBrowser() && window.location.pathname.includes(text)

  return (
    <li id={`ListWithSubItems_${slug}`} className="listWithSubItems">
      <Link
        data-bs-toggle="collapse"
        aria-controls={slug}
        to={`#${slug}`}
        aria-expanded={expandedList}
      >
        <i className="bi bi-chevron-right"></i>
        {textFilter} 
        <span className="articles-count">{subItemCount}</span>
      </Link>

      <ul
        className={`list-unstyled panel-collapse collapse list-subitems subitems-depth-${depth} ${
          expandedList ? "show" : null
        }`}
        id={slug}
      >
        {subItems.map((subItem, index) => {
          if (subItem.subItems?.length > 0) {
            subItemCount = getSubItemsCount(subItem.subItems)
            subItem.link = subItem.subItems[0].link
          }

          return (
            <div key={index}>
              {subItem.subItems?.length > 0 ? (
                depth > 0 ? (
                  <ListWithSubItems {...subItem} />
                ) : (
                  <li>
                  <Link to={subItem.link}>
                    {subItem.text.replace("-"," ")}
                    <span className="articles-count">{subItemCount}</span>
                  </Link>
                  </li>
                )
              ) : (
                <li>
                  <Link
                    to={subItem.link}
                    className={`${subItem.isActive ? "current-page-link" : ""}`}
                  >
                    {subItem.text.replace("-"," ")}
                  </Link>
                </li>
              )}
            </div>
          )
        })}
      </ul>
    </li>
  )
}

const isBrowser = () => typeof window !== "undefined"

export default ListWithSubItems
