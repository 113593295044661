import React, { useEffect, useState } from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import "./index.scss"
import ToC from "./toc"
import SideBar from "./side-bar"
import HeaderMain from "../../docs-header/header-main"
import RateModal from "./rate-modal"
import CodeBlock from "../../code-block"
import Search from "../../search"
import ZendeskButton from "../../zendesk"
import ImxLink from "../../imx-links/index"
import Seo from "../../seo"
import ImageDocs from "../docs-layout/image"
import NextButtton from "../docs-layout/next-button"
import XmlReader from "../../xml-reader"
import Blockquote from "../../blockquote-docs"
import NextPrev from "../../next-prev"
import Announcement from "../../Announcement"
import WidgetFooter from "../../widgets/widget-body/widget-footer/widget-footer-body"
import Figure from "../../figure"
import { isLoggedIn } from "../../../services/utils"
import LoginForm from "../../console/authentication/login/login-form"

const components = {
  pre: props => <div {...props} />,
  code: CodeBlock,
  ImxLink,
  ImageDocs,
  NextButtton,
  XmlReader,
  Blockquote,
  NextPrev,
  Announcement,
  Figure,
}
const LayoutDocs = ({
  slugParts = "Docs",
  pageTitle = "",
  headings = [],
  withToC = false,
  sideBarEntries = [],
  content,
  withBackToDocs,
  withRateModal = true,
  isClientOnly = false,
}) => {
  const [showPageContent, setShowPageContent] = useState(false)

  useEffect(() => {
    setShowPageContent(isClientOnly ? isLoggedIn() : true)
  }, [])

  return (
    <div id="LayoutDocs" className="layoutDocs px-sm-4 px-2">
      <Seo
        title={
          pageTitle === null
            ? slugParts.length === 0
              ? "IBM Maximo help to create your own app"
              : slugParts
            : pageTitle
        }
        description="IBM Maximo helps you create your own app by providing all the needed docs to enhance the work management systems, process, and strategy implementation."
      />
      <Helmet prepend>
        <meta
          property="og:title"
          content="MAXapps Docs: The MAXapps Way of Building"
        />
        <meta
          property="og:description"
          content="MAXapps is a Maximo mobile solution that enables you to build applications in compliance with your processes."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/docs.jpg"
        />
        <meta property="og:url" content="https://maxapps-maximo.com/docs" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta
          name="twitter:title"
          content="MAXapps Docs: The MAXapps Way of Building"
        />
        <meta
          name="twitter:description"
          content="MAXapps is a Maximo mobile solution that enables you to build applications in compliance with your processes."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/docs.jpg"
        />
      </Helmet>
      <HeaderMain />
      <div className="row ">
        <div className="page-sidebar fixed col-lg-3 col-xxl-2 ">
          <Search />
          <div className="ms-4 ms-lg-0 page-sidebar-sticky">
            <SideBar
              sideBarEntries={sideBarEntries}
              withBackToDocs={withBackToDocs}
            />
          </div>
          {withToC && showPageContent ? (
            <div className="  d-block d-lg-none pt-5 ">
              <ToC headings={headings} />
            </div>
          ) : (
            <></>
          )}
        </div>
        {content && showPageContent ? (
          <div
            className={`page-content scrollit col-12 col-lg-9 col-xxl-${
              withToC ? "7" : "9"
            }`}
          >
            {pageTitle && (
              <h1
                className="page-title"
                dangerouslySetInnerHTML={{ __html: pageTitle }}
              ></h1>
            )}
            <MDXProvider components={components}>
              <MDXRenderer>{content}</MDXRenderer>
            </MDXProvider>
            {withRateModal && <RateModal />}
            <WidgetFooter />
          </div>
        ) : (
          <div
            className={`page-content private-content-info scrollit row col-12 col-lg-9 col-xxl-9`}
          >
            <div className="private-content-message col-md-12">
              <h1>
                This is a private content for logged in clients only
                <br />
                please <span>login</span> or{" "}
                <Link to="/console/signup/">signup</Link>
              </h1>
            </div>
            <div className="login-form-container col-md-12">
              <LoginForm />
            </div>
          </div>
        )}

        {withToC && showPageContent ? (
          <div className="toc-docs col-lg-3 ">
            <ToC headings={headings} />
          </div>
        ) : (
          <></>
        )}
      </div>
      <ZendeskButton />
    </div>
  )
}

export default LayoutDocs
