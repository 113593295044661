import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

import "./index.scss"
import ListWithSubItems from "../sub-items"

const SideBarTitle = ({ subItems, text }) => {
  let srcImg = null
  try {
    const result = useStaticQuery(graphql`
      {
        site {
          siteMetadata {
            docsIcons {
              icon
              title
            }
          }
        }
      }
    `)

    srcImg = result?.site?.siteMetadata?.docsIcons?.find(
      elm => elm.title.toLowerCase() === text.toLowerCase()
    )
  } catch (error) {
    console.error(error)
  }

  return (
    <div id="SideBarTitle" className="row sideBarTitle">
      <div className="row title-container">
        <div className="title-image col-2">
          {srcImg && srcImg.icon && (
            <img alt="" className="pr-3" src={srcImg.icon} />
          )}
        </div>
        <div className="title-text col-9">
          <span className="pt-2 title">{text}</span>
        </div>
      </div>
      <div className="docs-links">
        <ul className="list-unstyled">
          {subItems.map((item, index) => {
            return (
              <li key={index}>
                {item.subItems?.length > 0 ? (
                  <ListWithSubItems {...item} />
                ) : (
                  <div>
                    <Link
                      to={item.link}
                      className={`item-title ${
                        item.isActive ? "current-page-link" : ""
                      }`}
                    >
                      {item.text}
                    </Link>
                  </div>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default SideBarTitle
