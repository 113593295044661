import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

export default connectSearchBox(({ refine, currentRefinement, onFocus }) => (
  <input
    className="form-control me-2 "
    type="search"
    placeholder="Search"
    aria-label="Search"
    onChange={e => refine(e.target.value)}
    value={currentRefinement}
    onFocus={onFocus}
  />
))
