import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { readFromStorage } from "../../../services/utils"

import "./index.scss"
const HeaderNavDocs = props => {
  const { _links } = props
  let links = []

  const [profile, setProfile] = useState(false)

  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(header-nav-dark)/" }) {
          frontmatter {
            title
          }
          exports {
            data {
              title
              to
            }
          }
        }
      }
    `)
    links = result.mdx.exports.data
  } catch (error) {
    links = _links
  }

  useEffect(() => {
    readFromStorage(({ profile }) => {
      setProfile(profile)
    })

    return () => {}
  }, [])

  return (
    <div
      id="HeaderNav"
      className="row justify-content-between HeaderNavDoc m-0 py-3 px-0"
      data-testid="HeaderNav"
    >
      <div className=" row col-12 col-lg-6 text-center text-lg-start m-0 p-0 ">
        <div className="d-block d-lg-none row">
          {links?.map((elt, index) => {
            return (
              <Link
                to={elt.to}
                className={"col-2 pt-2 linkStyle"}
                key={index}
                data-testid={index}
              >
                {elt.title === "Login" ? (
                  profile ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        viewBox="0 0 299 198"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M284.3,0H14.7A14.71,14.71,0,0,0,0,14.7V183.3A14.71,14.71,0,0,0,14.7,198H284.3A14.71,14.71,0,0,0,299,183.3V14.7A14.71,14.71,0,0,0,284.3,0ZM287,183.3a2.7,2.7,0,0,1-2.7,2.7H14.7a2.7,2.7,0,0,1-2.7-2.7V14.7A2.7,2.7,0,0,1,14.7,12H284.3a2.7,2.7,0,0,1,2.7,2.7Z" />
                            <path d="M106.52,87.81,54.12,52.44a3.31,3.31,0,0,0-.86-.33,3.25,3.25,0,0,0-.59-.07,2.7,2.7,0,0,0-2.75,2.76V76a4.77,4.77,0,0,0,1.51,3.8,15.82,15.82,0,0,0,2.69,2L77,96.06,54.12,110.47a20.67,20.67,0,0,0-2.69,1.84,4.53,4.53,0,0,0-1.51,3.8v21.22a2.7,2.7,0,0,0,2.75,2.75,2.55,2.55,0,0,0,.59-.07,3.62,3.62,0,0,0,.86-.32l52.4-35.37a13.26,13.26,0,0,0,2.81-2.3,4.67,4.67,0,0,0,1-3.21v-5.5a4.69,4.69,0,0,0-1-3.21A13.25,13.25,0,0,0,106.52,87.81Z" />
                            <path d="M158.91,135.22H117.56a1.84,1.84,0,0,0-1.84,1.84v9.71a1.84,1.84,0,0,0,1.84,1.84h41.35a1.86,1.86,0,0,0,1.85-1.84v-9.71a1.86,1.86,0,0,0-1.85-1.84Z" />
                          </g>
                        </g>
                      </svg>
                      Console
                    </>
                  ) : (
                    "Login"
                  )
                ) : (
                  elt.title !== "Home" && (
                    <span>
                      <span
                        className={
                          elt.title.toLowerCase() === "docs"
                            ? "linkStyleDocs"
                            : ""
                        }
                      >
                        {elt.title}
                      </span>
                      <span className="px-2">|</span>
                    </span>
                  )
                )}
              </Link>
            )
          })}
        </div>
      </div>
      <div className="row d-none d-lg-block col-lg-6  text-lg-end  ">
        {links?.map((elt, index) => {
          return (
            <Link
              to={elt.to}
              className={
                elt.title.toLowerCase() === "docs"
                  ? "linkStyle linkStyleDocs"
                  : "linkStyle"
              }
              key={index}
              data-testid={index}
            >
              {elt.title === "Login" ? (
                profile ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      viewBox="0 0 299 198"
                    >
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                          <path d="M284.3,0H14.7A14.71,14.71,0,0,0,0,14.7V183.3A14.71,14.71,0,0,0,14.7,198H284.3A14.71,14.71,0,0,0,299,183.3V14.7A14.71,14.71,0,0,0,284.3,0ZM287,183.3a2.7,2.7,0,0,1-2.7,2.7H14.7a2.7,2.7,0,0,1-2.7-2.7V14.7A2.7,2.7,0,0,1,14.7,12H284.3a2.7,2.7,0,0,1,2.7,2.7Z" />
                          <path d="M106.52,87.81,54.12,52.44a3.31,3.31,0,0,0-.86-.33,3.25,3.25,0,0,0-.59-.07,2.7,2.7,0,0,0-2.75,2.76V76a4.77,4.77,0,0,0,1.51,3.8,15.82,15.82,0,0,0,2.69,2L77,96.06,54.12,110.47a20.67,20.67,0,0,0-2.69,1.84,4.53,4.53,0,0,0-1.51,3.8v21.22a2.7,2.7,0,0,0,2.75,2.75,2.55,2.55,0,0,0,.59-.07,3.62,3.62,0,0,0,.86-.32l52.4-35.37a13.26,13.26,0,0,0,2.81-2.3,4.67,4.67,0,0,0,1-3.21v-5.5a4.69,4.69,0,0,0-1-3.21A13.25,13.25,0,0,0,106.52,87.81Z" />
                          <path d="M158.91,135.22H117.56a1.84,1.84,0,0,0-1.84,1.84v9.71a1.84,1.84,0,0,0,1.84,1.84h41.35a1.86,1.86,0,0,0,1.85-1.84v-9.71a1.86,1.86,0,0,0-1.85-1.84Z" />
                        </g>
                      </g>
                    </svg>
                    Console
                  </>
                ) : (
                  "Login"
                )
              ) : (
                elt.title
              )}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default HeaderNavDocs
