import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
} from "react-instantsearch-dom"
import "./index.scss"
const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="HitCount text-end">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : (
    <h5 className="no-result">
      No Result can be found, SORRY <i className="bi bi-emoji-frown-fill"></i>
    </h5>
  )
})

const PageHit = ({ hit }) => {
  return (
    <div className="PageHit">
      <div className="hits" data-bs-dismiss="modal" aria-label="Close">
        <Link to={"/docs/" + hit?.slug} className="link">
          <p>
            <Snippet hit={hit} attribute="excerpt" tagName="mark" />
          </p>
          <hr />
          <p>
            <i className="bi bi-arrow-return-right"></i>{" "}
            <Highlight attribute={`title`} hit={hit} tagName="mark" />
          </p>
        </Link>
      </div>

      <br />
    </div>
  )
}

const SearchResult = ({ indices }) => {
  return (
    <div className="className SearchResult">
      <div className="">
        {indices.map((indice, index) => (
          <Index key={index} indexName={indice.name}>
            <HitCount />
            <div className="searchHits  py-2">
              <Hits className="Hits" hitComponent={PageHit} />
            </div>
          </Index>
        ))}
      </div>
    </div>
  )
}

export default SearchResult
