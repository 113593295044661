import React, { useState } from "react"
import "./index.scss"
const RateEmotion = ({ getEmoji }) => {
  const [select, setSelect] = useState("2")
  return (
    <div id="RateEmotion" className="RateEmotion" data-testid="RateEmotion">
      <div className="rate-emotions">
        <button
          className={
            select === "1" ? "poor-emoji selected-emoji" : "poor-emoji"
          }
          data-testid="poorButton"
          onClick={() => {
            setSelect("1")
            getEmoji("1")
          }}
        >
          <svg
            className="poor-svg"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            aria-label="frowning face"
            height="50px"
            width="50px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15.5" cy="9.5" r="1.5"></circle>
            <circle cx="8.5" cy="9.5" r="1.5"></circle>
            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z"></path>
          </svg>
          <div>
            <span>poor</span>
          </div>
        </button>
        <button
          className={
            select === "2" ? "fine-emoji selected-emoji" : "fine-emoji"
          }
          data-testid="fineButton"
          onClick={() => {
            setSelect("2")
            getEmoji("2")
          }}
          // autoFocus
        >
          <svg
            className="fine-svg"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            aria-label="neutral face"
            height="50px"
            width="50px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9 14h6v1.5H9z"></path>
            <circle cx="15.5" cy="9.5" r="1.5"></circle>
            <circle cx="8.5" cy="9.5" r="1.5"></circle>
            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
          </svg>
          <div>
            <span>fine</span>
          </div>
        </button>
        <button
          className={
            select === "3" ? "great-emoji selected-emoji" : "great-emoji"
          }
          data-testid="greatButton"
          onClick={() => {
            setSelect("3")
            getEmoji("3")
          }}
        >
          <svg
            className="great-svg"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            aria-label="smiling face"
            height="50px"
            width="50px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.99 2C6.47 2 2 6.47 2 12s4.47 10 9.99 10S22 17.53 22 12 17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm1-10.06L14.06 11l1.06-1.06L16.18 11l1.06-1.06-2.12-2.12zm-4.12 0L9.94 11 11 9.94 8.88 7.82 6.76 9.94 7.82 11zM12 17.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"></path>
          </svg>
          <div>
            <span>great</span>
          </div>
        </button>
      </div>
    </div>
  )
}

export default RateEmotion
