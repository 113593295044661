import React from "react"
import { useState } from "react"
import "./index.scss"
import RateForm from "./rate-form"
import RateResponse from "./rate-response"

const RateDialog = ({ openAction }) => {
  const [sent, setSent] = useState(false)
  return (
    <div id="RateDialog" className="RateDialog" data-testid="RateDialog">
      {!sent && <RateForm openAction={openAction} sendAction={setSent} />}
      {sent && <RateResponse openAction={openAction} />}
    </div>
  )
}

export default RateDialog
